<template>
    <el-drawer :visible.sync="drawer" direction="rtl" destroy-on-close :append-to-body="true"
        size="860px">
        <iframe :src="url"></iframe>
    </el-drawer>
</template>
  
<script>
export default {
    data() {
        return {
            drawer:false,
            url:''
        };
    },    
    created(){
    },

    methods: {
        openForm(Id) {
            this.drawer = true
            this.url=location.href.split("#")[0] + '/#/SaleInfo?prop='+Id
        },
    },
};
</script>
  
<style lang="less" scoped>
  iframe{
    display: block;
    width:100%;
    height: 100%;
    border:none
  }
</style>
  