<template>
    <el-drawer
      :title="title"
      :visible.sync="drawerDetail"
      :direction="direction"
      :before-close="handleClose"
      destroy-on-close
      :append-to-body="true"
      :modal-append-to-body="false"
      custom-class="drawerClass"
      size="70%"
    >
      <!-- width:200px;border:1px solid blue -->
      <div id="qrcode" class="qrcode" ref="qrCodeUrl" style=""></div>
      <div class="searchNav" style="padding: 0 20px">
        <!-- <el-button
          class="button"
          icon="el-icon-document"
          type="primary"
          style="margin-left: 0"
          :loading="loading"
          @click="arrearListSend"
          >发送</el-button>
          <el-button class="button" type="text" @click="EditEmail"
          >配置邮箱</el-button> -->
        <!-- <div style="border:1px solid red">
          <img id="ewmsrc" width="340px" height="340px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAAAXNSR0IArs4c6QAAAqFJREFUeF7t0jENAAAMw7CVP+mhyOcC6BF5ZwoEBRZ8ulTgwIIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjp9QYIAl6bSsVAAAAAASUVORK5CYII=" />
        </div> -->
        <!-- <h2>联系人</h2> -->
        <div class="searchParams"></div>
      </div>
      <div style="padding: 15px 30px 10px 30px">
        <el-row style="margin-bottom: 5px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-row>
                <el-col :span="4"> 客户: </el-col>
                <el-col :span="18">
                  {{ entity.CustomerName }}
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="padding: 20px">
        <el-table
          :data="tableData"
          style="width: 100%"
          :max-height="height"
          v-loading="loading"
        >
          <el-table-column prop="CreateTime" label="日期">
            <template slot-scope="scope">
              {{ scope.row.CreateTime?.substring(0, 10) }}
            </template>
          </el-table-column>
          <el-table-column prop="OverdueDay" label="逾期天数">
            <template slot-scope="scope">
              {{ scope.row.OverdueDay+'天' }}
            </template>
          </el-table-column>
          <el-table-column prop="Yumoeney" label="逾期金额">
            <template slot-scope="scope">
              {{ toThousands(scope.row.Yumoeney) }}
            </template>
          </el-table-column>
            <el-table-column label="操作" width="70" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDetailCopy(scope.row)">详情</el-button>
              </template>
            </el-table-column>
        </el-table>
        <!-- <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
          :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
          style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
          @size-change="handleSizeChange">
        </el-pagination> -->
      </div>
  
      <div style="height: 50px"></div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '5px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 99999,
        }"
      >
        <div style="display: flex;justify-content: flex-end;padding-right:30px">
          <el-row :gutter="20" style="width: 450px; text-align: right; font-weight: bold;padding:10px 0">
            <el-col>
              <div style="">总计逾期金额: {{ toThousands(Yumoeney) }}</div>
            </el-col>
          </el-row>
        </div>
        <el-button @click="drawerDetail = false">取消</el-button>
      </div>
  
  
  
      <!-- 小票页面组件 -->
      <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" />
  
      <!-- 对账函组件 -->
      <Form ref="Form" :parentObj="{ ...this }" />
    </el-drawer>
  </template>
  
  <script>
  // import ProductDetail from './ProductDetail.vue'
  import { getMonthRange, toThousands,formatDate } from "../../utils/Tools";
  // 引入对账函组件
  import Form from "../Overdue/Form.vue";
//   import Email from "./Email.vue";
  // 引入小票页面
  import ReceiptPage from "../Overdue/Receipt.vue";
  import { log } from 'console';
  export default {
    components: {
    //   Email,
      ReceiptPage,
      Form,
    },
    props: {
      parentObj: { type: Object },
    },
    data() {
      return {
        isSave:false,
        loadingCurrent: null,
        sendMessage: "", // 转发内容
        getMonthRange,
        formatDate,
        toThousands,
        drawerDetail: false,
        direction: "rtl",
        btnLoading: false,
        title: "",
        loading: false,
        timeout: null,
        entity: {},
        tableData: [],
        pagination: {
          current: 1,
          pageSize: 25,
          total: 0,
          pageSizeOptions: [25, 50, 100],
        },
        sorter: {
          field: "CreateTime",
          order: "desc",
        },
        queryParam: {
          // 公司id
          CompanyId: "",
          // 客户id
          Keyword: "",
          //类型  客户还是业务员
          Type: 0,
        },
        sortList: [1, 2, 3, 4, 5, 6],
        selectRows: [],
        activeName: "2",
        tagList: [],
        dialogVisible: false,
        typeObj: {},
        dialogLoading: false,
        // 逾期详情总金额统计
        Yumoeney:0
      };
    },
    computed: {
      height() {
        return document.documentElement.clientHeight * 0.75;
      },
    },
    created() {
     
    },
    methods: {
  
  
      // 查看商品详情小票页面
      handleDetailCopy(row, index) {
       console.log('详情', row )
        if (this.loadingCurrent !== null) return; 
        this.typeObj = row;
        this.loadingCurrent = index; 
        // 备用
        // this.$http
        //   .post("/BO/BIlling/GetOrderBillReturnId?Id=" + row.BillId)
        //   .then((res) => {
        //     console.log('是否获取到了',res)
        //     if (res.Success) {
        //       const loading = this.$loading({
        //         lock: true,
        //         text: '加载中',
        //         spinner: 'el-icon-loading',
        //         background: 'rgba(0, 0, 0, 0.7)'
        //       });
        //       if(res.Data.Id ==""){
        //         this.$message.error('没有回执单信息')
        //         return
        //       }
        //       // 打开回执单页面
        //         this.$refs.Form.openForm(res.Data.Id);
        //         setTimeout(() => {
        //         loading.close();
        //       }, 2500);
        //     } else {
        //     }
        //   });
  
        // this.dialogVisible = true;
        const loading = this.$loading({
          lock: true,
          text: "加载中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        // 打开明细弹窗
        // this.$refs.receiptPage.openForm(row.Id);  不要删除
        this.$http
          .post("/BO/BIlling/GetOrderReturnId?Id=" + row.Id)
          .then((res) => {
            this.loadingCurrent = null;
            if (res.Success) {
              setTimeout(() => {
                loading.close();
              }, 2500);
              if (res.Data.Type == 0) {
                // 打开小票页面
                this.$refs.receiptPage.openForm(res.Data.Id);
              } else {
                // 打开回执单页面
                this.$refs.Form.openForm(res.Data.Id);
              }
            }
          });
      },
      currentChange(page) {
        this.pagination.current = page;
        this.getDataList();
      },
      handleSizeChange(lint) {
        this.pagination.pageSize = lint;
        this.getDataList();
      },
      init() {
        this.drawerDetail = true;
      },
  
  
      openForm(Id, CommpayId, Type) {
        console.log('出来的', Id, CommpayId, Type)
        
        this.init();
        this.title = "逾期统计";
        // this.range = getMonthRange(1);
        // this.queryParam.StartTime = this.range[0];
        // this.queryParam.EndTime = this.range[1];
        this.getDataList(Id, CommpayId, Type)
         // 获取逾期总金额统计数据
        this.GetOverdueDetailsDi(Id, CommpayId, Type)
      },

       // 获取逾期总金额统计数据
      GetOverdueDetailsDi(Id, CommpayId, Type){
        this.$http
          .post("/BO/BIlling/GetOverdueDetailsDi", {
            PageIndex: this.pagination.current,
            PageRows: this.pagination.pageSize,
            Search: {
              // 公司id
              CompanyId:CommpayId,
              // 客户id
              Keyword:Id,
              // 客户，还是业务员 
              Type:Type
            },
          })
          .then((resJson) => {
            if (resJson.Success) {
              console.log('获取详情统计结果',resJson.Data )
              this.loading = false;
              this.Yumoeney = resJson.Data.Yumoeney
             
            } else {
              this.loading = false;
            }
          });
      }, 
  
      // 获取逾期统计详情列表数据
      getDataList(Id,CommpayId,Type) {
        this.loading = true;
        this.$http
          .post("/BO/BIlling/GetOverdueDetails", {
            PageIndex: this.pagination.current,
            PageRows: this.pagination.pageSize,
            Search: {
              // 公司id
              CompanyId:CommpayId,
              // 客户id
              Keyword:Id,
              // 客户，还是业务员 
              Type:Type
            },
          })
          .then((resJson) => {
            this.loading = false;
            if (resJson.Success) {
              this.tableData = resJson.Data.map((item) => {
                return {
                  ...item,
                  loading: false,
                };
              });
              if (this.tableData) {
                const pagination = { ...this.pagination };
                pagination.total = resJson.Total;
                this.pagination = pagination;
              }
            }
          });
      },
  
      handleClose(done) {
        done();
      },
      handleSelect(item) {
        console.log(item);
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  /deep/.el-drawer__header {
    margin-bottom: 10px;
  }
  .qianImg {
    width: 70px;
    height: 160px;
    transform: rotate(-90deg);
  }
  
  .drawerClass {
    padding: 10px;
    .posBox {
      font-weight: 600;
      width: calc(100% - 42px);
      margin-left: 42px;
      line-height: 2;
      color: #333;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
  </style>
  