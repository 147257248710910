// 千分位 // 格式化千分位  保留两位小数
const toThousands = function (value) {
  if (!value) return "0.00";

  var intPart = Number(value) | 0; //获取整数部分

  let splitNums = parseFloat(value).toFixed(2).split(".");

  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分每三位加一个逗号

  var floatPart = ".00"; //预定义小数部分

  if (typeof value !== "string") {
    value = value.toString();
  }

  if (value == 0) {
    value = 0.0;
  }

  if (value) {
    var value2Array = value.split(".");
    //=2表示数据有小数位

    if (value2Array.length == 2) {
      floatPart = value2Array[1].toString(); //拿到小数部分

      if (floatPart.length > 2) {
        //若小数位数在两位以上，则四舍五入保留两位小数

        return intPartFormat + "." + splitNums[1];
      } else if (floatPart.length == 1) {
        //若小数位数只有一位，补0

        return intPartFormat + "." + floatPart + "0";
      } else {
        return intPartFormat + "." + floatPart;
      }
    } else {
      //数据为整数

      return intPartFormat + floatPart;
    }
  }
};

// 获取前一个月份后一个月份
const getMonthRange = function (time) {
  let year = time.getFullYear(); // 获取当前年份
  let month = time.getMonth() + 1; // 获取当前月份（需要加1，因为月份从0开始计数）
  let day = time.getDate(); // 获取当前日期
  let hours = time.getHours(); // 获取当前小时数
  let minutes = time.getMinutes(); // 获取当前分钟数
  let seconds = time.getSeconds(); // 获取当前秒数
  // 格式化日期和时间
  let formattedDate =
    year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);
  let formattedTime =
    addLeadingZero(hours) +
    ":" +
    addLeadingZero(minutes) +
    ":" +
    addLeadingZero(seconds);

  function addLeadingZero(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return formattedDate;
};
// 文件下载

const download = function (url, name = "") {
  let token = sessionStorage.getItem("token");
  function createObjectURL(object) {
    return window.URL
      ? window.URL.createObjectURL(object)
      : window.webkitURL.createObjectURL(object);
  }
  var xhr = new XMLHttpRequest();
  var formData = new FormData();
  xhr.open("get", url); //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
  xhr.setRequestHeader("Authorization", "Bearer " + token);
  xhr.responseType = "blob";
  xhr.onload = function (e) {
    if (this.status == 200) {
      var blob = this.response;
      var filename = name; //这里的名字，可以按后端给的接口固定表单设置一下名字，如（费用单.xlsx,合同.doc等等）
      console.log(this.response);
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        var a = document.createElement("a");
        var url = createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  };
  xhr.send(formData);
};
// 环境判断
const isMobile = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};
// base64
function Base64() {
  // private property
  var _keyStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  // public method for encoding
  this.encode = function (input) {
    if (!input) {
      console.log("请传入要加密的值");
      return;
    }
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;
    input = _utf8_encode(input);
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output =
        output +
        _keyStr.charAt(enc1) +
        _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) +
        _keyStr.charAt(enc4);
    }
    return output;
  };

  // public method for decoding
  this.decode = function (input) {
    if (!input) {
      console.log("请传入要解密的值");
      return;
    }
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }
    output = _utf8_decode(output);
    return output;
  };

  // private method for UTF-8 encoding
  var _utf8_encode = function (string) {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  };
  // private method for UTF-8 decoding
  var _utf8_decode = function (utftext) {
    var string = "";
    var i = 0;
    let c = 0,
      c1 = 0,
      c2 = 0;
    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c1 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c1 & 63)
        );
        i += 3;
      }
    }
    return string;
  };
}

// 格式化成年月日
// 使用方式
// var year = getDateTime('year');
// console.log(year);  // 2021
// var month = getDateTime('month');
// console.log(month); // 2021-12
// var date = getDateTime('');
// console.log(date);  // 2021-12-03 09:00:00
// 日期格式化年月日函数
const formatDate = function (type, format) {
  var date = new Date();
  var hengGang = "-";
  var maoHao = ":";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var curDate = date.getDate();
  var curHours = date.getHours();
  var curMinutes = date.getMinutes();
  var curSeconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (curDate >= 0 && curDate <= 9) {
    curDate = "0" + curDate;
  }
  if (curHours >= 0 && curHours <= 9) {
    curHours = "0" + curHours;
  }
  if (curMinutes >= 0 && curMinutes <= 9) {
    curMinutes = "0" + curMinutes;
  }
  if (curSeconds >= 0 && curSeconds <= 9) {
    curSeconds = "0" + curSeconds;
  }
  var currentdate = "";
  if (type == "format") {
    // 传递需要的日期 格式化成年月日 只要年月日  format是传递的日期参数
    currentdate = format.slice(0, 16);
    return currentdate;
  } else if (type == "year") {
    // 获取年份
    currentdate = year;
    return currentdate;
  } else if (type == "month") {
    // 获取年份月份
    currentdate = year + hengGang + month;
    return currentdate;
  } else if (type == "day") {
    // 获取年月日
    currentdate = year + hengGang + month + hengGang + curDate;
    return currentdate;
  } else if (type == "minutes") {
    // 获取年月日 时分
    currentdate =
      year +
      hengGang +
      month +
      hengGang +
      curDate +
      " " +
      curHours +
      maoHao +
      curMinutes;
  } else {
    // 获取年月日 + 当前时间
    currentdate =
      year +
      hengGang +
      month +
      hengGang +
      curDate +
      " " +
      curHours +
      maoHao +
      curMinutes +
      maoHao +
      curSeconds;
    return currentdate;
  }
};
export { toThousands, download, isMobile, getMonthRange, formatDate, Base64 };
